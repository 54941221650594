import { post, put } from '../../../helpers/api';

export const createSubscription = async (
  pkg,
  packageToken,
  paymentMethod,
  selectedPlan,
  licenses,
  cardholderName,
  subscriptionId,
  isTrial = false
) => {
  const result = { error: null, subscription: null };

  try {
    const response = await post(`payment/create-subscription?stripeAccount=${pkg.stripeAccount}`, {
      paymentMethod,
      packageId: pkg.id,
      partnerToken: packageToken ? packageToken : null,
      cardholderName: isTrial ? null : cardholderName,
      subscriptionId: subscriptionId ? subscriptionId : null,
      isTrial,
      selectedPlanId: selectedPlan.id,
      licenses: licenses,
    });

    if (!response.ok) {
      if (response.status === 402) return { ...result, error: response.body };
      return { ...result, error: 'There was an issue with the payment.' };
    }

    return { ...result, subscription: response.body };
  } catch (e) {
    console.log(e);
    return { ...result, error: e };
  }
};

export const upgradeSubscription = async (
  pkg,
  paymentMethod,
  selectedPlan,
  licenses,
  cardholderName,
  isTrial = false
) => {
  try {
    const result = { error: null, subscription: null };
    const response = await post(`payment/upgrade-subscription`, {
      paymentMethod,
      packageId: pkg.id,
      cardholderName,
      subscriptionId: null,
      selectedPlanId: selectedPlan.id,
      licenses: licenses,
      isTrial: isTrial,
    });
    if (!response.ok) {
      if (response.status === 402) return { ...result, error: response.body };
      return { ...result, error: 'There was an issue with the payment.' };
    }

    return { ...result, subscription: response.body };
  } catch (e) {
    console.log(e);
    return { ...result, error: e };
  }
};

export const createContract = async (pkg, packageToken, subscription, companyId) => {
  try {
    const response = await post(
      `company/${companyId}/contract?stripeAccount=${pkg.stripeAccount}`,
      {
        subscription,
        packageId: pkg.id,
        partnerToken: packageToken ? packageToken : null,
      }
    );

    if (response.ok) return { error: null, result: 'success' };
    return { error: response.body, result: null };
  } catch (e) {
    return { error: e, result: null };
  }
};

export const cancelSubscription = async (subscriptionId, stripeAccount) => {
  try {
    const response = await post(`payment/cancel-subscription`, {
      subscriptionId,
      stripeAccount,
    });
    if (response.ok) return { error: null, result: 'success' };
    return { error: response.body, result: null };
  } catch (error) {
    return { error: e, result: null };
  }
};

export const updatePaymentMethod = async (
  pkg,
  packageToken,
  paymentMethod,
  selectedPlan,
  licenses,
  cardholderName,
  subscriptionId,
  isTrial = false
) => {
  const result = { error: null, subscription: null };

  try {
    const response = await post(
      `payment/update-payment-method?stripeAccount=${pkg.stripeAccount}`,
      {
        paymentMethod,
        packageId: pkg.id,
        partnerToken: packageToken ? packageToken : null,
        cardholderName: isTrial ? null : cardholderName,
        subscriptionId: subscriptionId ? subscriptionId : null,
        isTrial,
        selectedPlanId: selectedPlan.id,
        licenses: licenses,
      }
    );

    if (!response.ok) {
      if (response.status === 402) return { ...result, error: response.body };
      return { ...result, error: 'There was an issue in updating payment method.' };
    }

    return { ...result, subscription: response.body };
  } catch (e) {
    console.log(e);
    return { ...result, error: e };
  }
};
