import styled from '@emotion/styled';
import theme from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  align-items: stretch;

  @media screen and (min-width: 1079px) {
    flex-wrap: nowrap;
  }
`;

export const ColumnOne = styled.div`
  background-color: ${(props) =>
    props.light ? theme.color.shade.marble : theme.color.shade.space};
  display: flex;
  flex-grow: 1;
  padding: 160px 32px 64px;
  position: relative;

  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};

  @media screen and (min-width: 1079px) {
    flex-grow: 0;
    margin-right: auto;
  }

  @media screen and (min-width: 1100px) {
    width: 44.444444%;
  }

  p {
    max-width: 360px;
  }

  svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const ColumnTwo = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 144px 32px 64px 32px;

  @media screen and (min-width: 1100px) {
    padding: 144px 64px 64px;
  }
`;

export const ColumnContainer = styled.div`
  width: ${(props) => (props.wide ? '648px' : '432px')};
  height: 100%;

  .header {
    margin-bottom: 32px;

    + p {
      margin-bottom: 32px;
      margin-top: 0;
    }
  }
`;

export const FormContainer = styled.div`
  width: 432px;
  height: 100%;

  form {
    height: calc(100% - 126px);
  }

  .header {
    margin-bottom: 32px;

    + p {
      margin-bottom: 32px;
      margin-top: 0;
    }
  }

  .form-item {
    padding-bottom: 32px;
    margin-bottom: 0;
  }

  .submit-button {
    margin-top: 16px;
    display: block;
    width: 100%;
  }

  .input-label {
    margin-bottom: 8px;
  }

  .select-label {
    margin-bottom: 8px;
  }

  .forgot-password {
    color: ${theme.color.extended.blue};
    text-decoration: none;
    margin-bottom: 32px;
  }

  .ul {
    margin: 32px 0 16px 0;

    div {
      margin-bottom: 24px;
    }

    list-style: none;
    li {
      a {
        color: ${theme.color.basalt};
        &:hover {
          color: ${theme.color.extended.blue};
        }
      }
      a:link {
        text-decoration: none;
      }

      a:visited {
        text-decoration: none;
      }

      a:active {
        text-decoration: none;
      }

      cursor: pointer;
      margin-bottom: 8px;
      &:hover {
        color: ${theme.color.extended.blue};
        transform: translate(4px, 0);
        transition: all ${theme.easing.default} ease;
      }
      svg {
        transition: all ${theme.easing.default} ease;
        transform: rotate(90deg) scale(0.75);
        margin-right: 8px;
        path {
          stroke: ${theme.color.extended.blue};
        }
      }
    }
  }
`;

export const PasswordInputContainer = styled.div`
  .form-item {
    padding-bottom: 0px;
    margin-bottom: 0;
  }
`;

export const ButtonContainer = styled.div`
  .submit-button {
    margin-top: 32px;
    display: block;
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  div:first-of-type {
    margin-right: 16px;
  }
`;

export const StyledPaymentForm = styled.form`
  .header {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 32px;
  }
  .card-expiry-cvc {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(50% - 8px);
    }
  }

  button {
    width: 100%;
  }
`;

export const Summary = styled.div`
  border-top: 1px solid ${theme.color.shade.stone};
`;

export const SummaryItem = styled.div`
  display: grid;
  grid-template-columns: 244px repeat(2, minmax(72px, 1fr));
  padding: 32px 0;
  border-bottom: 1px solid ${theme.color.shade.stone};

  .items {
    justify-self: start;
  }

  .totals {
    justify-self: end;
  }
`;

export const SummaryText = styled.div`
  padding: 32px 0;

  p {
    margin-bottom: 16px;
  }
`;

export const SummaryTotal = styled.div`
  display: grid;
  grid-template-columns: minmax(52px, 1fr) minmax(96px, 1fr);
  padding: 32px 0;
  border-bottom: 1px solid ${theme.color.shade.stone};

  .totals {
    justify-self: end;
  }
`;
