import React, { Component } from 'react'
import styled from '@emotion/styled'
import theme from '../../styles/theme'
import Toggle from './Toggle'
import Icon from '../../assets/svg/trash.svg'

const Content = styled.div`
    padding: 12px 16px;
    border-radius: ${theme.border.default};
    border: ${props =>
            props.borderless ? 'transparent' : theme.color.shade.stone}
        2px solid;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Title = styled.h6`
    font-size: 16px;
    color: ${theme.color.ink};
    font-weight: 600;
    text-transform: none;
    margin-right: auto;
`
const Title2 = styled.h6`
    font-size: 16px;
    color: ${theme.color.ink};
    font-weight: 600;
    text-transform: none;
    margin-right: auto;
    margin-top: 0;
`
const SVGcontainer = styled.div`
    display: ${props => (props.delete ? 'block' : 'none')};
    margin: 0 32px 0 0;
    cursor: pointer;
    flex-shrink: 0;
`
export default class ToggleCard extends Component {
    constructor() {
        super()
        this.state = {
            toggle: false,
        }
    }
    componentDidMount() {
        this.setState({ toggle: this.props.toggleCard })
    }

    componentDidUpdate(prev) {
        if (this.props.toggleCard !== prev.toggleCard) {
            this.setState({
                toggle: this.props.toggleCard,
            })
        }
    }

    toggleSwitch() {
        this.props.toggleSwitch(this.props.type, this.props.id)

        this.setState({
            toggle: !this.props.toggleCard,
        })
    }

    deleteColumn = () => {
        this.props.deleteColumn(this.props.id)
    }

    handleCloseGoalModal() {
        this.setState({ showGoalModal: false })
    }

    render() {
        return (
            <>
                <Content borderless={this.props.borderless} className="toggle-card" style={this.props.style}>
                    <Title>{this.props.title}</Title>
                    <SVGcontainer
                        onClick={() => this.deleteColumn()}
                        delete={this.props.delete}
                    >
                        {/* Put the onclick on the icon */}
                        <Icon></Icon>
                    </SVGcontainer>
                    <Toggle
                        toggle={this.state.toggle}
                        onClick={e => this.toggleSwitch(e)}
                    ></Toggle>
                    {
                        this.props.title2 &&
                        <Title2>{this.props.title2}</Title2>
                    }
                </Content>
            </>
        )
    }
}
