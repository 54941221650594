import React from 'react';
import styled from '@emotion/styled';
import PaymentProcessingImage from '../../../../assets/svg/payment-processing.svg';

const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  height: 410px;
  width: 320px;
  border-radius: 28px;
  box-shadow: 0 11px 23px 0 rgba(0, 0, 0, 0.18);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-evenly;
  flex-wrap: wrap;

  .body {
    .header {
      font-weight: 600;
      font-size: 1rem;
      padding: 0;
      text-align: center;
    }

    .text {
      text-align: center;
    }
  }
`;

function PaymentLoading({ active }) {
  if (!active) return null;
  return (
    <Container>
      <Modal>
        <PaymentProcessingImage />
        <div className="body">
          <div className="header">Please Wait</div>
          <div className="text">Your payment is processing</div>
        </div>
      </Modal>
    </Container>
  );
}

export default PaymentLoading;
