import styled from '@emotion/styled';
import theme from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 82px 0 0 184px;
  .first {
    width: 40%;
    font-weight: 600;
    .error {
      font-size: 1.5em;
      margin-bottom: 24px;
      div {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 4px;
        margin-left: 8px;
        width: 96px;
        height: 2px;
        background-color: ${theme.color.accent};
      }
    }
    .title {
      font-size: 4em;
      margin: 12px 0;
    }
    .subtitle {
      font-size: 3em;
      margin: 12px 0;
    }

    ul {
      margin: 32px 0 16px 0;

      div {
        margin-bottom: 24px;
      }

      font-size: 1.25em;
      list-style: none;
      li {
        a {
          color: ${theme.color.basalt};
          &:hover {
            color: ${theme.color.extended.blue};
          }
        }
        a:link {
          text-decoration: none;
        }

        a:visited {
          text-decoration: none;
        }

        a:active {
          text-decoration: none;
        }

        cursor: pointer;
        margin-bottom: 8px;
        &:hover {
          color: ${theme.color.extended.blue};
          transform: translate(4px, 0);
          transition: all ${theme.easing.default} ease;
        }
        svg {
          transition: all ${theme.easing.default} ease;
          transform: rotate(90deg) scale(0.75);
          margin-right: 8px;
          path {
            stroke: ${theme.color.extended.blue};
          }
        }
      }
    }

    .col {
      flex: 1;
    }
  }
  .logo {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
