import React from 'react'
import styled from '@emotion/styled'
import theme from '../../styles/theme'
import { LightenDarkenColor } from "../../helpers/LightenDarkenColor"

const RadioContainer = styled.div`
  position:relative;

  + .radio-container {
    margin-top:8px;
  }
`;

const RadioLabel = styled.label`
  display:flex;
  flex-direction:column;
  padding:0px 0px 0px 24px;
  transition:all ${theme.easing.default};

  .label-plain {
    /* Paragraph-2LeftBasalt */
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    color: ${theme.color.basalt};
  }
`

const RadioInput = styled.input`
    position:absolute;
    clip: rect(0,0,0,0);
    clip: rect(0 0 0 0);

    + label::before {
      content:"";
      display:block;
      position:absolute;
      left:0;
      top:50%;
      transform:translateY(-50%);
      box-sizing:border-box;
      height:16px;
      width:16px;
      border-radius:50%;
      border:2px solid ${theme.color.shade.granite};
      background-color:${theme.color.shade.white};
    }

    + label::after {
      content:"";
      display:block;
      position:absolute;
      left:0;
      top:50%;
      transform:translateY(-50%);
      box-sizing:border-box;
      height:16px;
      width:16px;
      border-radius:50%;
      border:5px solid ${theme.color.extended.turquoise};
      background-color:${theme.color.shade.white};
      opacity:0;
      transition:opacity ${theme.easing.default};
    }

    &:checked + label::after {
      opacity:1;
    }
`
export const Radio = ({labelClassName = '', inputRef, radioName, radioID, className, children, ...rest}) => {
  return (
    <RadioContainer className="radio-container">
      <RadioInput 
        className={`radio-input ${className ? className : ''}`} 
        type="radio"
        ref={inputRef}
        name={radioName}
        id={radioID}
        {...rest}
        
      />
      <RadioLabel className={`radio-label ${labelClassName ? labelClassName : ''}`} htmlFor={radioID}>
        {children}
      </RadioLabel>
    </RadioContainer>
      
  )
}
