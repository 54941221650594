import React, { Component } from 'react'
import styled from '@emotion/styled'
import theme from '../../styles/theme'

const Container = styled.div`
    width: 36px;
    padding: 4px;
    border-radius: 20px;
    margin: 0;
    background: ${props =>
        props.toggle
            ? theme.color.extended.turquoise
            : theme.color.shade.stone};
    display: flex;
    transition: 0.4s;
    cursor: pointer;
    flex-shrink: 0;
`
const Knob = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #fff;
    transform: ${props =>
        props.toggle ? 'translate(16px,0)' : 'translate(0,0)'};
    transition: 0.4s;
`

export default class Toggle extends Component {
    render() {
        return (
            <Container onClick={this.props.onClick} toggle={this.props.toggle} className="toggle">
                <Knob toggle={this.props.toggle} />
            </Container>
        )
    }
}
