import React from 'react';
import styled from '@emotion/styled';
import theme from '../../styles/theme';
import { v4 } from 'uuid';

const CheckboxStyled = styled.div`
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
      --active: ${theme.color.extended.turquoise};
      --active-check: ${theme.color.shade.white};
      --background: ${theme.color.shade.white};
      --disabled: ${theme.color.shade.quartz};
      --border: ${theme.color.shade.granite};
      --border-hover: ${theme.color.extended.turquoise};
      --focus: 2px ${theme.color.shade.quartzhover};
      --check-opacity: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      display: inline-block;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 2px solid var(--border);
      background: var(--background);
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      border-radius: 2px;
      width: 16px;
      height: 16px;
      bottom: 0; /* override setting in reset.scss */

      &::after {
        content: '';
        display: block;
        position: absolute;
        opacity: var(--check-opacity);
        width: 5px;
        height: 10px;
        border: 2px solid var(--active-check);
        border-top: 0;
        border-left: 0;
        left: 4px;
        top: 0px;
        transform: rotate(var(--r, 20deg));
      }

      &:checked {
        --background: var(--active);
        --border: var(--active);
        --check-opacity: 1;
        --r: 43deg;
        &:disabled {
          --active-check: ${theme.color.shade.granite};
          --border: ${theme.color.shade.granite};
        }
      }

      &:disabled {
        --background: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;

        & + label {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --border: var(--border-hover);
          }
        }
      }

      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
    }
  }
`;
const CheckboxLabel = styled.label`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: ${theme.color.basalt};
  padding-left: 8px;
  cursor: pointer;
`;

const Checkbox = ({ label, className, children, ...rest }) => {
  const id = v4();

  return (
    <CheckboxStyled className="checkbox-wrapper">
      <input
        className={`checkbox-input ${className ? className : ''}`}
        type="checkbox"
        name={id}
        id={rest.id || id}
        {...rest}
      />
      <CheckboxLabel className="checkbox-label" htmlFor={rest.id || id}>
        {label}
      </CheckboxLabel>
    </CheckboxStyled>
  );
};

export default Checkbox;
