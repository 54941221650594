import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Radio } from '../../../forms/Radio';
import Input from '../../../forms/Input';
import Button from '../../../ui/Button';
import {
  StyledAdjustPlanCard,
  CardColumn,
  CardColumnBordered,
  CardColumnContainer,
  Summary,
} from './components';

export class AdjustPlanCard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    total: null,
  };

  getFormatter = (currency) => {
    const locale = currency === 'gbp' ? 'en-GB' : 'en-US';
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    });
  };

  get total() {
    const totalLicences = this.props.licenses;
    const defaultLicences = this.props.selectedPlan.tiers[0].up_to;
    const additionalPrice =
      (totalLicences - defaultLicences) * this.props.selectedPlan.tiers[1].unit_amount;
    let total = additionalPrice + this.props.selectedPlan.tiers[0].flat_amount;

    if (this.props.selectedPlan.interval === 'year') {
      total /= 12;
    }
    return total;
  }

  get yearlyDiscount() {
    const planYear = this.props.selectedPackage.stripeProduct.stripePrices.filter(
      (price) => price.interval === 'year'
    );
    const planMonth = this.props.selectedPackage.stripeProduct.stripePrices.filter(
      (price) => price.interval === 'month'
    );

    const baseRateYearly = planYear[0].tiers[0].flat_amount;
    const baseRateMonthly = planMonth[0].tiers[0].flat_amount * 12;
    const discountAmount = baseRateMonthly - baseRateYearly;

    return Math.floor((discountAmount / baseRateMonthly) * 100);
  }

  componentDidMount = () => {
    this.setState({ total: this.props.selectedPlan.tiers[0].flat_amount });
  };

  createMarkup = (wysiwyg) => {
    return { __html: wysiwyg };
  };

  handleUpdateLicences = (e) => {
    const newLicences = +e.target.value;
    const [defaultTier] = this.props.selectedPlan.tiers;
    if (newLicences < defaultTier.up_to) return;
    this.props.handleLicenceState(newLicences);
  };

  get priceText() {
    if (this.props.selectedPlan.interval === 'year') return 'Per month, billed upfront annually ';
    return 'Billed Monthly';
  }

  render() {
    if (!this.props.selectedPackage) {
      return null;
    }

    const formatter = this.getFormatter(this.props.selectedPlan.currency);

    return (
      <StyledAdjustPlanCard>
        <header>
          <h3 className="Heading-6-Left-Basalt">{this.props.selectedPackage.title}</h3>
        </header>
        <CardColumnContainer>
          <CardColumn>
            <p className="text">{this.props.selectedPackage.description}</p>
            <div
              className="wysiwyg"
              dangerouslySetInnerHTML={this.createMarkup(this.props.selectedPackage.details)}
            ></div>
          </CardColumn>
          <CardColumn>
            <CardColumnBordered>
              <fieldset className="radio-group">
                <legend className="Heading-8-Left-Basalt">Billing Period</legend>
                {this.props.selectedPackage.stripeProduct.stripePrices.map((plan, index) => {
                  const discount = Math.ceil(this.yearlyDiscount);

                  return (
                    <Radio
                      key={`package-${index}`}
                      className="radio-input"
                      labelClassName="radio-label-borderless"
                      radioName="plan"
                      radioID={`plan-${index}`}
                      checked={this.props.selectedPlan.id === plan.id}
                      onChange={() => {
                        this.props.handlePlanSelection(plan);
                      }}
                    >
                      <span className="label-plain">
                        {plan.interval}
                        {plan.interval === 'year' ? (
                          <span className="Paragraph-3LeftBasalt discount">-{discount}%</span>
                        ) : null}
                      </span>
                    </Radio>
                  );
                })}
              </fieldset>
              <Input
                type="number"
                min={this.props.selectedPlan.tiers[0].up_to}
                name="licenses"
                label="Number of Licences"
                value={this.props.licenses.toString()}
                onChange={(e) => {
                  this.handleUpdateLicences(e);
                }}
              />
              <Summary>
                <div className="amount Heading-6-Left-Basalt">
                  {formatter.format(this.total / 100)}
                </div>
                <span>{this.priceText}</span>
              </Summary>
            </CardColumnBordered>
            <Button
              label={this.props.isFreeTrial ? 'Start Free Trial' : 'Continue to Payment'}
              onClick={() => {
                this.props.handleContinue();
              }}
            />
          </CardColumn>
        </CardColumnContainer>
      </StyledAdjustPlanCard>
    );
  }
}

export default withRouter(AdjustPlanCard);
