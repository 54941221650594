export default {
  color: {
    accent: '#7ceff3',
    primary: '#3d79f2',
    basalt: '#051133',
    light: '#f5f5fa',
    light2: '#d1d1d6',
    light3: '#c7c7cc',
    border: '#efeff4',
    modal: 'rgba(0, 0, 0, 0.5)',
    extended: {
      coral: '#ff4d70',
      orange: '#ff7c4d',
      gold: '#ffa64d',
      yellow: '#fec34c',
      turquoise: '#00c5cc',
      azure: '#33a0ff',
      blue: '#334eff',
      ultramarine: '#5a00e0',
      purple: '#8700cd',
      violet: '#ad00b8',
      aqua: '#7ceff3',
    },
    shade: {
      space: '#16203d',
      diabase: '#2a3552',
      flint: '#454e66',
      slate: '#6d768f',
      steel: '#9aa2b8',
      granite: '#ced3e0',
      stone: '#e1e4eb',
      quartz: '#f1f2f6',
      quartzpressed: '#c1c2c5',
      quartzhover: '#d8d9dc',
      marble: '#f7f9fc',
      white: '#ffffff',
    },
    fill: {
      zero25: 'rgba(0, 0, 0, 0.25)',
    },
  },
  shadow: {
    long: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
    card: '0 1px 1px -1px rgba(22, 32, 61, 0.32), 0 0 0 1px rgba(22, 32, 61, 0.08);',
  },

  border: {
    default: '4px',
    double: '8px',
    wide: '16px',
  },
  maxwidth: {
    default: '1400px',
    intro: '1024px',
    column: '320px',
    modal: {
      xl: '992px',
      large: '800px',
      medium: '600px',
      small: '400px',
    },
  },
  maxheight: {
    modal: '800px',
  },
  breakpoints: {
    mobile: '512px',
    tablet: '768px',
    desktop: '992px',
    hd: '1200px',
  },
  easing: {
    default: '0.2s',
  },
  nav: {
    height: '80px',
  },
};
