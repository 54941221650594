export const jobFields = [
  {
    label: 'Please select a value',
    value: '',
  },
  {
    label: 'Administrative Services',
    value: 'Administrative Services',
  },
  {
    label: 'Advertising',
    value: 'Advertising',
  },
  {
    label: 'Agriculture and Farming',
    value: 'Agriculture and Farming',
  },
  {
    label: 'Apps',
    value: 'Apps',
  },
  {
    label: 'Artificial Intelligence',
    value: 'Artificial Intelligence',
  },
  {
    label: 'Biotechnology',
    value: 'Biotechnology',
  },
  {
    label: 'Clothing and Apparel',
    value: 'Clothing and Apparel',
  },
  {
    label: 'Commerce and Shopping',
    value: 'Commerce and Shopping',
  },
  {
    label: 'Community and Lifestyle',
    value: 'Community and Lifestyle',
  },
  {
    label: 'Consumer Electronics',
    value: 'Consumer Electronics',
  },
  {
    label: 'Consumer Goods',
    value: 'Consumer Goods',
  },
  {
    label: 'Content and Publishing',
    value: 'Content and Publishing',
  },
  {
    label: 'Data and Analytics',
    value: 'Data and Analytics',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Energy',
    value: 'Energy',
  },
  {
    label: 'Events',
    value: 'Events',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Food and Beverage',
    value: 'Food and Beverage',
  },
  {
    label: 'Gaming',
    value: 'Gaming',
  },
  {
    label: 'Government and Military',
    value: 'Government and Military',
  },
  {
    label: 'Hardware',
    value: 'Hardware',
  },
  {
    label: 'Health Care',
    value: 'Health Care',
  },
  {
    label: 'Information Technology',
    value: 'Information Technology',
  },
  {
    label: 'Internet Services',
    value: 'Internet Services',
  },
  {
    label: 'Lending and Investments',
    value: 'Lending and Investments',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Media and Entertainment',
    value: 'Media and Entertainment',
  },
  {
    label: 'Messaging and Telecommunications',
    value: 'Messaging and Telecommunications',
  },
  {
    label: 'Mobile',
    value: 'Mobile',
  },
  {
    label: 'Music and Audio',
    value: 'Music and Audio',
  },
  {
    label: 'Natural Resources',
    value: 'Natural Resources',
  },
  {
    label: 'Navigation and Mapping',
    value: 'Navigation and Mapping',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Payments',
    value: 'Payments',
  },
  {
    label: 'Platforms',
    value: 'Platforms',
  },
  {
    label: 'Privacy and Security',
    value: 'Privacy and Security',
  },
  {
    label: 'Professional Services',
    value: 'Professional Services',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Sales and Marketing',
    value: 'Sales and Marketing',
  },
  {
    label: 'Science and Engineering',
    value: 'Science and Engineering',
  },
  {
    label: 'Software',
    value: 'Software',
  },
  {
    label: 'Sports',
    value: 'Sports',
  },
  {
    label: 'Sustainability',
    value: 'Sustainability',
  },
  {
    label: 'Transportation',
    value: 'Transportation',
  },
  {
    label: 'Travel and Tourism',
    value: 'Travel and Tourism',
  },
  {
    label: 'Video',
    value: 'Video',
  },
];

export const jobRoles = [
  {
    label: 'Please select a value',
    value: '',
  },
  {
    label: 'Co-Founder / C-Suite',
    value: 'Co-Founder / C-Suite',
  },
  {
    label: 'Management',
    value: 'Management',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Admin / Operations',
    value: 'Admin / Operations',
  },
  {
    label: 'Support / Customer Success',
    value: 'Support / Customer Success',
  },
  {
    label: 'Technical',
    value: 'Technical',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const employees = [
  { value: '', label: 'Please select a value' },
  { value: '1-2', label: '1 - 2' },
  { value: '3-5', label: '3 - 5' },
  { value: '6-10', label: '6 - 10' },
  { value: '11-20', label: '11 - 20' },
  { value: '21-30', label: '21 - 30' },
  { value: '31-50', label: '31 - 50' },
  { value: '50+', label: '50+' },
];
