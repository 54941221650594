import '../styles/main.scss';

function start() {
  console.log('Render the bundle: ');
  console.log(window.BUNDLE);
  switch (window.BUNDLE) {
    case 'app':
      const app = import('./app').then((app) => app.render());
      break;
    case 'homepage':
      const homepage = import('./homepage').then((homepage) => homepage.render());
      break;
    // case 'homepage':
    //   const home = import('./homepage').then((hp) => hp.render());
    //   break;
    // case 'login':
    //   const login = import('./login').then((login) => login.render());
    //   break;
    // case 'payment':
    //   const payment = import('./payment').then((payment) => payment.render());
    //   break;
    // case 'passwordReset':
    //   const passwordReset = import('./passwordReset').then((passwordReset) =>
    //     passwordReset.render()
    //   );
    //   break;
    // case 'invite':
    //   const invite = import('./invite').then((invite) => invite.render());
    //   break;
  }
}

start();
