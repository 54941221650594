import React, { Component } from 'react'
import styled from '@emotion/styled'

const LoaderLogo = styled.svg`
    position: absolute;
    width: 90px;
    height: 90px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
`

export class Loader extends Component {
    render() {
        return (
            <LoaderLogo
                viewBox="0 0 90 90"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="loader"
            >
                <g>
                    <g id="logogram" fill="#7CEFF3" fillRule="nonzero">
                        <path
                            d="M63,0 C77.912,0 90,12.088 90,27 L63,27 L63,0 Z"
                            className="loader__path__4"
                        />
                        <polygon
                            className="loader__path__3"
                            points="58.5 0 58.5 27 31.5 27 31.5 0"
                        />
                        <polygon
                            className="loader__path__3"
                            points="90 31.5 90 58.5 63 58.5 63 31.5"
                        />
                        <path
                            d="M58.5,31.5 L58.5,58.5 C43.588,58.5 31.5,46.412 31.5,31.5 L58.5,31.5 Z"
                            className="loader__path__2"
                        />
                        <path
                            d="M27,0 L27,27 L13.5,27 C6.044,27 0,20.956 0,13.5 C0,6.044 6.044,0 13.5,0 L27,0 Z"
                            className="loader__path__1"
                        />
                        <path
                            d="M90,63 L90,76.5 C90,83.956 83.956,90 76.5,90 C69.044,90 63,83.956 63,76.5 L63,63 L90,63 Z"
                            className="loader__path__1"
                        />
                    </g>
                </g>
            </LoaderLogo>
        )
    }
}

export default Loader
