import fetch from 'isomorphic-fetch';

const headers = {
  'Content-Type': 'application/json',
};

export function apiException(status, body) {
  return {
    status,
    app: true,
    ok: false,
    body,
  };
}

export function apiSuccess(body) {
  return {
    ok: true,
    status: 200,
    app: true,
    body,
  };
}

const makeAuthenticatedRequest = async (url, opts) => {
  opts.headers = opts.headers || {};

  return fetch(url, opts)
    .then((body) => body.json())
    .then((response) => {
      if (response.status === 401) {
        // alert('You have been logged out because you logged in with a different user.');
        window.location = '/user/logout';
        return;
      }
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.resolve({
        ok: false,
        app: false,
        message: e.message,
      });
    });
};

async function putOrPost(entity, record = {}, method) {
  return await makeAuthenticatedRequest(`/api/${entity}`, {
    method,
    body: JSON.stringify(record),
    headers,
  });
}

async function getOrDelete(entity, method) {
  return await makeAuthenticatedRequest(`/api/${entity}`, {
    method,
    headers,
  });
}

export async function post(entity, record) {
  return putOrPost(entity, record, 'POST');
}

export async function put(entity, record) {
  return putOrPost(entity, record, 'PUT');
}

export async function patch(entity, record) {
  return putOrPost(entity, record, 'PATCH');
}

export async function apiDelete(entity) {
  return getOrDelete(entity, 'DELETE');
}

export async function get(entity) {
  return getOrDelete(entity, 'GET');
}

// Gets error from express-validator response
export const getValidationError = (errors, field) => {
  if (!errors) return null;
  const error = errors.find((e) => e.param === field);
  if (!error) return null;
  return error.msg;
};

export default {
  post,
  put,
  apiDelete,
  get,
  getValidationError,
};
