import React from 'react';
import Icon from './Icon';
import styled from '@emotion/styled';
import theme from '../../styles/theme';
import { LightenDarkenColor } from '../../helpers/LightenDarkenColor';

const Button = styled.button`
  background: ${theme.color.accent};
  border: ${theme.color.accent} 2px solid;
  color: ${theme.color.basalt};
  padding: 12px 32px;
  font-size: 1rem;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border: 0;
  border-radius: ${theme.border.default};
  font-weight: 600;
  transition: 0.4s;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${LightenDarkenColor(theme.color.accent, 20)};
  }

  .icon {
    object-fit: contain;
    object-position: center;

    svg {
      display: block;
    }
  }

  &.has-icon {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 8px;
    }
  }

  &.is-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.is-secondary {
    border: ${theme.color.accent} 2px solid;
    background: transparent;
    color: ${theme.color.basalt};
    &:hover,
    &:focus {
      border: ${LightenDarkenColor(theme.color.accent, 20)} 2px solid;
      color: ${LightenDarkenColor(theme.color.accent, 20)} 2px solid;
    }
  }
  &.is-secondary-alt {
    border: ${theme.color.accent} 2px solid;
    background: transparent;
    color: ${theme.color.basalt};
    &:hover,
    &:focus {
      border: ${LightenDarkenColor(theme.color.primary, 20)} 2px solid;
      color: ${LightenDarkenColor(theme.color.primary, 20)} 2px solid;
    }
  }
  &.is-tertiary {
    border: transparent;
    background: ${theme.color.shade.quartz};
    color: ${theme.color.basalt};
    &:hover,
    &:focus {
      color: ${LightenDarkenColor(theme.color.primary, 20)};
    }
  }
  &.is-warning {
    border: transparent;
    background: ${theme.color.extended.coral};
    color: #fff;
    padding: 12px 32px;
    &:hover,
    &:focus {
      background: ${LightenDarkenColor(theme.color.extended.coral, 20)};
      color: #fff;
    }
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.is-neutral {
    border: transparent;
    background: ${theme.color.shade.quartz};
    color: ${theme.color.basalt};
    &:hover,
    &:focus {
      background: ${LightenDarkenColor(theme.color.shade.quartz, -20)};
    }
  }
  &.is-plus {
    &:before {
      content: '+';
      margin-right: 8px;
    }
  }
  &.is-cta {
    &:before {
      content: '+';
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      border-radius: 4px;
      margin-right: 8px;
      background: #7ceff3;
    }
  }
  &.is-small {
    padding: 0;
    height: 24px;
    min-width: 24px;
  }
  &.is-small-text {
    padding: 0 12px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.14px;
  }
  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.is-disabled {
    border: ${theme.color.extended.aqua} 2px solid;
    opacity: 0.5;
    background: transparent;
    color: ${theme.color.basalt};
    cursor: not-allowed;
  }

  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

export default ({
  isSecondary,
  isSecondaryAlt,
  isTertiary,
  isSmall,
  isSmallText,
  isBig,
  isInverted,
  isLoading,
  isPadded,
  isPlus,
  isCTA,
  isWarning,
  isNeutral,
  isFullwidth,
  className,
  icon,
  isDisabled,
  ...rest
}) => {
  const classes = [
    isSecondary ? 'is-secondary' : false,
    isSecondaryAlt ? 'is-secondary-alt' : false,
    isTertiary ? 'is-tertiary' : false,
    isSmall ? 'is-small' : false,
    isSmallText ? 'is-small-text' : false,
    isBig ? 'is-big' : false,
    isInverted ? 'is-inverted' : false,
    isLoading ? 'is-loading' : false,
    isPadded ? 'is-padded' : false,
    isPlus ? 'is-plus' : false,
    isCTA ? 'is-cta' : false,
    isWarning ? 'is-warning' : false,
    isNeutral ? 'is-neutral' : false,
    isFullwidth ? 'is-fullwidth' : false,
    icon && !rest.label ? 'is-icon' : false,
    icon && rest.label ? 'has-icon' : true,
    isDisabled ? 'is-disabled' : false,
    className ? className : false,
  ]
    .filter((c) => c)
    .join(' ');

  return (
    <Button className={classes} {...rest}>
      {rest.iconAlign === 'left' ? null : rest.label}
      {icon ? <Icon name={icon} className="icon" /> : null}
      {rest.iconAlign !== 'left' ? null : rest.label}
    </Button>
  );
};
