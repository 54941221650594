import styled from '@emotion/styled';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 80px);
  justify-content: center;
  align-items: stretch;

  @media screen and (min-width: 1140px) {
    flex-wrap: nowrap;
  }
`;

export const ColumnOne = styled.div`
  background-color: ${(props) =>
    props.light ? theme.color.shade.marble : theme.color.shade.quartz};
  display: flex;
  flex-grow: 1;
  padding: 64px 15px;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};

  @media screen and (min-width: 1140px) {
    flex-grow: 0;
    margin-right: auto;
    width: 41.111111111%;
    padding: 64px;
  }

  @media screen and (min-width: 1100px) {
  }
`;

export const ColumnTwo = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 64px 15px;

  @media screen and (min-width: 1140px) {
    padding: 64px;
  }
`;

export const ColumnContainer = styled.div`
  width: calc(100vw - 30px);
  height: 100%;

  @media screen and (min-width: 1140px) {
    width: ${(props) => (props.wide ? '648px' : '432px')};
  }

  form {
    height: calc(100% - 126px);
  }

  .header {
    margin-bottom: 32px;

    + p {
      margin-bottom: 32px;
      margin-top: 0;
    }
  }

  .form-item {
    padding-bottom: 32px;
    margin-bottom: 0;
  }

  .submit-button {
    margin-top: 16px;
    display: block;
    width: 100%;
  }

  .input-label {
    margin-bottom: 8px;
  }

  .select-label {
    margin-bottom: 8px;
  }

  .forgot-password {
    color: ${theme.color.extended.blue};
    text-decoration: none;
    margin-bottom: 32px;
  }
`;

export const StyledAdjustPlanCard = styled.div`
  padding: 32px 0;
  border: 2px solid ${theme.color.shade.stone};
  border-radius: 8px;

  /* Paragraph-2LeftBasalt */
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: ${theme.color.basalt};

  header {
    margin-bottom: 32px;
    padding: 0 32px;
  }

  fieldset {
    margin-bottom: 24px;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  legend {
    text-transform: initial;
    top: 0;
    padding: 0;
    margin: 0 0 8px 0;
  }

  label {
    /* Heading-8-Left-Basalt */
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    color: ${theme.color.basalt};
  }

  .discount {
    padding: 0 8px;
    background-color: #e0f4f4;
    border-radius: 4px;
    margin-left: 8px;
  }

  .wysiwyg {
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      /* Heading-8-Left-Basalt */
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.16px;
      color: ${theme.color.basalt};

      margin-bottom: 8px;
    }

    p {
      margin-bottom: 8px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li {
        padding-left: 21px;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          top: 8px;
          content: '';
          display: block;
          width: 9px;
          height: 5px;
          border-width: 0 0 2px 2px;
          border-style: solid;
          border-color: ${theme.color.extended.turquoise};
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

export const CardColumn = styled.div`
  padding: 0 32px;
  flex: 1;

  .text {
    margin-bottom: 24px;
  }

  button {
    width: 100%;
  }
`;

export const CardColumnBordered = styled.div`
  border-left: 1px solid ${theme.color.shade.stone};
  margin: 0 -32px;
  padding: 0 32px;
`;

export const CardColumnContainer = styled.div`
  display: flex;
`;

export const Summary = styled.div`
  background-color: ${theme.color.shade.marble};
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 32px;

  .amount {
    margin-bottom: 8px;
  }
`;
