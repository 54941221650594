import queryString from 'query-string';

export const redirectTo = (history, pathname, queryStringObj, options) => {
  history.push({
    pathname,
    search: queryString.stringify(queryStringObj),
    ...options,
  });
};

export const redirectToUsingReplace = (history, pathname, queryStringObj, options) => {
  history.replace({
    pathname,
    search: queryString.stringify(queryStringObj),
    ...options,
  });
};

// Redirect user to the appropriate page depending on how far they have gotten in the sing up process
export const redirectUserToCorrectPage = (history, queryStringObj, user) => {
  if (!user) {
    redirectToUsingReplace(history, '/sign-up-user', queryStringObj);
    return;
  } else if (user && !user.companies.length) {
    redirectToUsingReplace(history, '/sign-up-company', queryStringObj);
    return;
  } else if (!user.profile || !user.profile.field) {
    redirectToUsingReplace(history, '/sign-up-profile', queryStringObj);
    return;
  } else if (
    user &&
    user.companies.length &&
    user.profile.field &&
    !user.companies[0].contract.id
  ) {
    redirectToUsingReplace(history, '/package-selection', queryStringObj);
    return;
  } else if (user && user.companies.length && user.profile.field && user.companies[0].contract.id) {
    window.location = '/app';
  }
};
