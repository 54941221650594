import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { RadioFancy } from '../../../forms/RadioFancy';
import { Container, ColumnTwo, ColumnOne, ColumnContainer } from './components';
import { AdjustPlanCard } from './AdjustPlanCard';
import ErrorMessage from '../../../ui/ErrorMessage';
import { get } from '../../../../helpers/api';
import { redirectTo, redirectToUsingReplace } from '../../Homepage/utils';
import { createSubscription, createContract } from '../../Payment/utils';

export class AdjustPlan extends Component {
  state = {
    pkgs: [],
    licenses: 0,
    selectedPkg: null,
    selectedPlan: null,
    errors: {
      packages: [],
    },
    isLoading: false,
  };

  queryString = queryString.parse(this.props.location.search);

  componentDidMount = async () => {
    if (!this.props.user) {
      redirectToUsingReplace(this.props.history, '/login');
    }
    if (!this.props.countryCode) return;
    await this.getData();
  };

  getData = async () => {
    let response = null;

    const { packageToken, planId } = this.queryString;
    const packageId = +this.queryString.packageId;

    if (packageToken) response = await get(`public/packages/partner/${packageToken}`);
    else response = await get(`public/packages?countryCode=${this.props.countryCode}`);

    if (!response.ok) {
      const errors = { ...this.state.errors };
      if (response.status === 400 || response.status === 404) {
        errors.packages.push(
          'The package link provided is incorrect, please check it and try again'
        );
      } else errors.packages.push('There was an issue retrieving the packages');
      this.setState({ errors });
      return;
    }

    /*  If packages is empty */
    if (!response.body.length) {
      const errors = { ...this.state.errors };

      errors.packages.push('No packages found');
      this.setState({ errors });
      return;
    }

    /*  Set selected package */
    let selectedPackage;

    selectedPackage = response.body.find((pkg) => pkg.id === packageId);

    /* if not in company package id set to first package in response */
    if (!selectedPackage) {
      selectedPackage = response.body[0];
    }

    /*  If plans is empty */
    if (!selectedPackage.stripeProduct.stripePrices.length) {
      const errors = { ...this.state.errors };

      errors.packages.push('No plans found');
      this.setState({ errors });
      return;
    }

    /* Set selected plan */
    let selectedPlan;

    selectedPlan = selectedPackage.stripeProduct.stripePrices.find((plan) => plan.id === planId);

    if (!selectedPlan) {
      selectedPlan = selectedPackage.stripeProduct.stripePrices[0];
    }

    /* Set licenses */
    const licenses = selectedPlan.tiers[0].up_to;

    this.setState({
      selectedPkg: selectedPackage,
      licenses: licenses,
      pkgs: response.body,
      selectedPlan: selectedPlan,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.countryCode !== this.props.countryCode) {
      await this.getData();
    }
  };

  handleLicenceState = (licenses) => {
    this.setState({ licenses });
  };

  handlePkgSelection = (selectedPackage) => {
    this.setState({
      selectedPkg: selectedPackage,
      selectedPlan: selectedPackage.stripeProduct.stripePrices[0],
      licenses: selectedPackage.stripeProduct.stripePrices[0].tiers[0].up_to,
    });
  };

  handleContinue = async () => {
    this.queryString.packageId = this.state.selectedPkg.id;
    this.queryString.planId = this.state.selectedPlan.id;
    this.queryString.licenses = this.state.licenses;

    if (this.props.isUpgrade) {
      redirectTo(this.props.history, '/app/payment', this.queryString);
    } else {
      let response = null;

      /* Retrieve Package */
      if (this.queryString.packageId) {
        const packageId = +this.queryString.packageId;

        response = await get(`public/packages/${packageId}?countryCode=${this.props.countryCode}`);
      } else {
        response = await get(`public/packages?countryCode=${this.props.countryCode}`);
      }

      if (!response.ok || !response.body.length) {
        const errors = { ...this.state.errors };

        errors.contract.push('No package found, url is wrong');
        this.setState({ errors });
        this.setState({ isLoading: false });
        return;
      }

      const selectedPackage = response.body[0];

      /* Find Plan */

      /* Set selected plan */
      const selectedPlan = selectedPackage.stripeProduct.stripePrices.find(
        (plan) => plan.id === this.queryString.planId
      );

      if (!selectedPlan) {
        const errors = { ...this.state.errors };

        errors.contract.push('No plan found, url is wrong');
        this.setState({ errors });
        return;
      }

      const subscriptionResult = await createSubscription(
        selectedPackage,
        null,
        null,
        selectedPlan,
        selectedPlan.tiers[0].up_to,
        null,
        null,
        true
      );

      if (subscriptionResult.error) {
        const errors = { ...this.state.errors };

        errors.contract.push('Error subscription could not be created', subscriptionResult.error);
        this.setState({ errors });
        return;
      }

      const { subscription } = subscriptionResult;

      const result = await createContract(
        selectedPackage,
        null,
        subscription,
        this.props.user.companies[0].id
      );

      if (result.error) {
        const errors = { ...this.state.errors };

        errors.contract.push('Contract Error');
        this.setState({ errors });
        this.setState({ isLoading: false });
        return;
      }

      this.setState({ isLoading: false });

      window.location = `/app`;

      return;
    }
  };

  handlePlanSelection = (selectedPlan) => {
    this.setState({ selectedPlan: selectedPlan });
  };

  render() {
    return (
      <Container>
        <ColumnOne center>
          <ColumnContainer>
            <h1 className="Heading-5-Left-Basalt header">Select Plan</h1>
            <ErrorMessage isError={this.state.errors.packages.length}>
              {this.state.errors.packages[0]}
            </ErrorMessage>
            {this.state.pkgs.map((pkg, index) => {
              return (
                <RadioFancy
                  key={`package-${index}`}
                  className="radio-input"
                  labelClassName="radio-label"
                  radioName="package"
                  radioID={index}
                  checked={
                    this.state.selectedPkg && this.state.selectedPkg.id === pkg.id ? true : false
                  }
                  onChange={() => this.handlePkgSelection(pkg)}
                >
                  <span className="label-heading">{pkg.title}</span>
                  <span className="label-description">{pkg.description}</span>
                  <span className="label-description">{pkg.info[2]}</span>
                </RadioFancy>
              );
            })}
          </ColumnContainer>
        </ColumnOne>
        <ColumnTwo>
          <ColumnContainer wide>
            <h1 className="Heading-5-Left-Basalt header">Adjust Plan</h1>
            {this.state.selectedPkg && this.state.selectedPlan ? (
              <AdjustPlanCard
                selectedPackage={this.state.selectedPkg}
                handlePlanSelection={this.handlePlanSelection}
                selectedPlan={this.state.selectedPlan}
                handleContinue={this.handleContinue}
                handleLicenceState={this.handleLicenceState}
                licenses={this.state.licenses}
                isFreeTrial={!this.props.isUpgrade}
              ></AdjustPlanCard>
            ) : null}
          </ColumnContainer>
        </ColumnTwo>
      </Container>
    );
  }
}

export default withRouter(AdjustPlan);
