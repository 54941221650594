import React, { useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { post, get } from '../../../../helpers/api';
import Input from '../../../forms/Input';
import { RadioFancy } from '../../../forms/RadioFancy';
import Button from '../../../ui/Button';
import ErrorMessage from '../../../ui/ErrorMessage';
import { FormContainer, StyledPaymentForm } from '../../Homepage/components';
import PaymentLoading from './PaymentLoading';
import PaymentSuccess from './PaymentSuccess';
import { createSubscription, createContract, upgradeSubscription } from '../utils';
import styled from '@emotion/styled';

const FormItem = styled.div`
  padding: 20px 0;
  width: 100%;
`;

const PaymentForm = ({
  pkg,
  packageToken,
  user,
  isFreeTrial,
  selectedPlan,
  licenses,
  isUpgrade,
  companyId,
  hasPaymentData,
  cardBrand,
  cardExpiry,
  cardName,
  paymentMethods,
  cardEnding,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState('');
  const [formErrors, setFormErrors] = useState({ cardholderName: [] });
  const [cardValidationError, setCardValidationError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [newCard, setNewCard] = useState(false);
  if (!stripe || !elements) return <div>Loading payment form...</div>;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    resetErrors();

    if (!cardholderName) {
      displayFormError('cardholderName', 'Is required');
      setIsLoading(false);
      return;
    }

    // If it's an upgrade remove all payment methods
    // Carry on with the normal process of creating new payment method
    // If it's an upgrade Cancel any existing subscription in stripe and create a new one. Maybe set the payment method on the current subscription if it's in trial mode to the payment method provided or past due date payment?
    // Create new Subscription as normal, if i decide not to do the above updating existing subscription
    // amend existing contract to match the chosen package

    if (isUpgrade) {
      const response = await post('payment/remove-all-payment-methods');

      if (!response.ok) {
        setCardValidationError(
          'There was an issue with upgrading/downgrading your package. Please refresh and try again. If the issue persists please contact support'
        );
        setIsLoading(false);
        return;
      }
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    let result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: cardholderName,
      },
    });

    if (result.error) {
      checkErrors(result.error);
      setIsLoading(false);
      return;
    }

    const { paymentMethod } = result;

    if (isUpgrade) {
      result = await upgradeSubscription(
        pkg,
        paymentMethod,
        selectedPlan,
        licenses,
        cardholderName
      );
    } else {
      result = await createSubscription(
        pkg,
        packageToken,
        paymentMethod,
        selectedPlan,
        licenses,
        cardholderName,
        localStorage.getItem('subscriptionId'),
        false
      );
    }

    if (result.error) {
      setCardValidationError(result.error);
      setIsLoading(false);
      return;
    }

    const { subscription } = result;
    if (subscription.status === 'active') {
      localStorage.removeItem('subscriptionId');

      if (!isUpgrade) {
        //Set the state to success  and show success modal
        const result = await createContract(pkg, packageToken, subscription, user.companies[0].id);
        if (result.error) {
          setCardValidationError(
            'Payment was successful however there was an issue when creating your account. Please contact support to get this sorted. Do noy pay again!'
          );
          setIsLoading(false);
          return;
        }
      }

      // Success stuff
      setIsLoading(false);
      setIsSuccess(true);
      return;
    }

    if (
      subscription.latest_invoice.payment_intent &&
      (subscription.latest_invoice.payment_intent.status === 'requires_action' ||
        (localStorage.getItem('subscriptionId') &&
          subscription.latest_invoice.payment_intent.status === 'requires_payment_method'))
    ) {
      const result = await handlePaymentThatRequiresCustomerAction(subscription, paymentMethod);
      if (result.error) {
        // Start code flow to handle updating the payment details.
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc).
        localStorage.setItem('subscriptionId', subscription.id);
        setCardValidationError(result.error.message);
        setIsLoading(false);
        return;
      }

      if (result.paymentIntent.status === 'succeeded') {
        localStorage.removeItem('subscriptionId');

        // only do this if it's not through the upgrade screen
        if (!isUpgrade) {
          const result = await createContract(
            pkg,
            packageToken,
            subscription,
            user.companies[0].id
          );
          if (result.error) {
            setCardValidationError(
              'Payment was successful however there was an issue when creating your account. Please contact support to get this sorted.'
            );
            setIsLoading(false);
            return;
          }
        }
        // Do success stuff here
        setIsLoading(false);
        setIsSuccess(true);
        return;
      }
    }
    if (subscription.status === 'trialing') {
      // Do success stuff here
      // upgrade contract
      setIsLoading(false);
      setIsSuccess(true);
      return;
    }

    // If it gets to this point then something has gone wrong that we haven't considered
    // we save the subscription id for when the user tries again
    if (subscription) {
      localStorage.setItem('subscriptionId', subscription.id);
    }
    setCardValidationError(
      'Something has wrong with the payment. Please can you refresh and try a different payment method. If the issue persists then please contact support.'
    );
    setIsLoading(false);
  };

  const handleSubmitCardDetails = async (event) => {
    let result = null;
    let response = null;

    event.preventDefault();

    setIsLoading(true);

    result = await upgradeSubscription(pkg, paymentMethods, selectedPlan, licenses, cardName);

    if (result.error) {
      setCardValidationError(result.error);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setIsSuccess(true);
  };

  const handlePaymentThatRequiresCustomerAction = async (subscription, paymentMethod) => {
    const paymentIntent = subscription.latest_invoice.payment_intent;
    const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: paymentMethod.id,
    });
    return result;
  };

  const resetErrors = () => {
    setCardValidationError(null);
    setFormErrors({ cardholderName: [] });
  };

  const checkErrors = (error) => {
    if (error.type === 'validation_error') setCardValidationError(error.message);
  };

  const handleInputChange = (e) => {
    setCardholderName(e.target.value);
  };

  const displayFormError = (field, message) => {
    const newFormErrors = { ...formErrors };
    newFormErrors[field].push(message);
    setFormErrors(newFormErrors);
  };

  const handleFreeTrialSubmit = async (event) => {
    event.preventDefault();
  };

  const handleNewCard = async (event) => {
    event.preventDefault();
    setNewCard(true);
  };

  const cardInfo = () => {
    let cardBrandUpper = cardBrand && cardBrand[0].toUpperCase() + cardBrand.slice(1);
    return cardBrandUpper + ' ending in ' + cardEnding;
  };

  return (
    <FormContainer>
      <PaymentSuccess active={isSuccess} />
      <PaymentLoading active={isLoading} />
      {isFreeTrial ? (
        <StyledPaymentForm onSubmit={handleFreeTrialSubmit}>
          <ErrorMessage isError={cardValidationError}>{cardValidationError}</ErrorMessage>
          <Button
            label="Start free trial"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </StyledPaymentForm>
      ) : (
        <>
          {hasPaymentData && !newCard ? (
            <>
              <h2 className="Heading-5-Left-Basalt header">Payment Details</h2>
              <RadioFancy checked={true} readOnly={true}>
                <span>
                  <span className="label-heading">
                    {cardBrand && cardBrand[0].toUpperCase() + cardBrand.slice(1)}
                  </span>
                  <span className="label-description"> ending in </span>
                  <span className="label-heading">{cardEnding}</span>
                </span>
                <span>
                  <span className="label-description">Exp: </span>
                  <span className="label-heading">{cardExpiry}</span>
                </span>
                <span className="label-heading">{cardName}</span>
              </RadioFancy>
              <FormItem>
                <Button
                  isPlus={true}
                  isTertiary={true}
                  isFullwidth={true}
                  onClick={handleNewCard}
                  label="Add a new card"
                />
              </FormItem>
              <StyledPaymentForm onSubmit={handleSubmitCardDetails}>
                <Button
                  label="Complete Payment"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </StyledPaymentForm>
            </>
          ) : (
            <StyledPaymentForm onSubmit={handleSubmit}>
              <h2 className="Heading-5-Left-Basalt header">Payment Details</h2>
              <ErrorMessage isError={cardValidationError}>{cardValidationError}</ErrorMessage>
              <>
                <div>
                  <Input
                    isError={formErrors.cardholderName.length}
                    errorMessage={formErrors.cardholderName.length && formErrors.cardholderName[0]}
                    name="cardholderName"
                    placeholder="Cardholder Name"
                    label="Cardholder Name"
                    value={cardholderName}
                    onChange={handleInputChange}
                    labelClassName="input-label Heading-8-Left-Basalt"
                  />
                </div>
                <div className="form-item">
                  <div className="input-label Heading-8-Left-Basalt">Card Number</div>
                  <CardNumberElement />
                  <div className="card-expiry-cvc">
                    <div className="card-expiry-container">
                      <CardExpiryElement />
                    </div>
                    <div className="card-cvc-container">
                      <CardCvcElement />
                    </div>
                  </div>
                </div>
              </>
              <Button
                label="Complete Payment"
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </StyledPaymentForm>
          )}
        </>
      )}
    </FormContainer>
  );
};

export default PaymentForm;
