import React, { Component } from 'react';
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import Arrow from '../../../assets/svg/arrow-up.svg';
import BrandAsset from '../../../assets/svg/brand-asset.svg';
import { Container } from './Components';
import { get } from '../../../helpers/api';

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playbookLink: null,
    };
  }

  async componentDidMount() {
    const header = document.querySelector('header');
    if (header && !this.props.user) header.style.display = 'none';

    this.createPlayBookLink();
  }

  createPlayBookLink = async () => {
    const workflowPermission = this.props.user.workflowPermissions.find(
      (wp) => wp.companyId === this.props.selectedCompany
    );
    if (!workflowPermission) return;
    const isSalesCycleEnabled = workflowPermission.show.includes('execution.sales-cycle');
    if (!isSalesCycleEnabled) return;
    const salesResponse = await get(`sales-cycle/company/${this.props.selectedCompany}`);
    let salesCycle = null;
    if (salesResponse.ok && salesResponse.body.length) {
      salesCycle = salesResponse.body[0];
    }
    if (!salesCycle) return;

    const firstStage = salesCycle.stages[0];
    if (!firstStage) return;

    const firstStep = firstStage.steps[0];
    if (!firstStep) return;

    let playbookLink = `/app/sales-cycle/${salesCycle.id}/stage/${firstStage.id}/step/${firstStep.id}`;

    this.setState({ playbookLink });
  };

  render() {
    return (
      <Container>
        <div className="col first">
          <div className="error">
            Error 404<div></div>
          </div>
          <div className="title"> Oops...</div>

          <div className="subtitle"> We can't find the page you're looking for.</div>

          {!this.props.user && (
            <ul className="ul">
              <div> Let us help, take me to:</div>
              <li>
                <Arrow />
                <Link to="/login">Login</Link>
              </li>
            </ul>
          )}

          {this.props.user && (
            <ul className="ul">
              <div> Let us help, take me to:</div>
              <li>
                <Arrow />
                <Link to="/app">Home</Link>
              </li>
              <li>
                <Arrow />
                <Link to="/app/sales-cycle">Sales Cycles</Link>
              </li>
              {this.state.playbookLink && (
                <li>
                  <Arrow />
                  <Link to={this.state.playbookLink}>Sales Playbooks</Link>
                </li>
              )}
            </ul>
          )}

          <ul className="ul">
            <div> Or if you need any help, visit:</div>
            <li>
              <Arrow />
              <a href="https://www.stackglobal.io/contact-us">Contact</a>
            </li>
          </ul>
        </div>

        <div className="col logo">
          <BrandAsset />
        </div>
      </Container>
    );
  }
}

export default withRouter(index);
