import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  ColumnContainer,
  Summary,
  SummaryItem,
  SummaryText,
  SummaryTotal,
} from '../../Homepage/components';

export class OrderSummary extends Component {
  /* move to utils */
  getFormatter = (plan) => {
    const locale = plan.currency === 'gbp' ? 'en-GB' : 'en-US';
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: plan.currency,
    });
  };

  queryString = queryString.parse(this.props.location.search);

  get plan() {
    const { planId } = this.queryString;

    if (this.props.pkg) {
      return this.props.pkg.stripeProduct.stripePrices.find((plan) => plan.id === planId);
    }
  }

  get licenses() {
    const { planId } = this.queryString;

    if (this.props.pkg) {
      const plan = this.props.pkg.stripeProduct.stripePrices.find((plan) => plan.id === planId);
      return plan.tiers[0].up_to;
    }
  }

  get extraLicences() {
    const { planId, licenses } = this.queryString;

    if (this.props.pkg) {
      const plan = this.props.pkg.stripeProduct.stripePrices.find((plan) => plan.id === planId);
      return licenses - plan.tiers[0].up_to;
    }
  }

  priceDetails = () => {
    if (this.plan) {
      const formatter = this.getFormatter(this.plan);
      const planPrice = this.plan.tiers[0].flat_amount / 100;
      const monthPlanPrice = planPrice / 12;
      if (this.plan.interval === 'month') {
        return '1x' + formatter.format(planPrice);
      } else {
        return formatter.format(monthPlanPrice) + ' x 12 months';
      }

      return '';
    }
  };

  componentDidMount() {}

  render() {
    if (!this.props.pkg) return <ColumnContainer>Loading...</ColumnContainer>;
    const formatter = this.getFormatter(this.plan);
    const planPrice = this.plan.tiers[0].flat_amount / 100;
    const licensePrice = this.plan.tiers[1].unit_amount / 100;
    const priceDetails = this.priceDetails();

    let hasFreeTrial = false;
    if (this.props.isUpgrade) {
      hasFreeTrial = this.props.user.companies[0].contract.subscription.status === 'trialing';
    }

    return (
      <ColumnContainer wide>
        <h2 className="Heading-5-Left-Basalt header">Order Summary</h2>
        <Summary>
          <SummaryItem>
            <div className="inline-header Paragraph-2LeftBasalt">
              <div className="title Heading-8-Left-Basalt">{this.props.pkg.title}</div>
              <div>
                {this.licenses} Licences | Billed {this.plan.interval}ly
              </div>
            </div>
            <div className="items Paragraph-2LeftSlate">{priceDetails}</div>
            <div className="totals Button-Left-Blue">{formatter.format(planPrice)}</div>
          </SummaryItem>
          {this.extraLicences > 0 ? (
            <SummaryItem>
              <div className="inline-header Paragraph-2LeftBasalt">
                <div className="title Heading-8-Left-Basalt">
                  {this.extraLicences} Additional Licence{this.extraLicences > 1 ? 's' : ''}
                </div>
                <div>Billed {this.plan.interval}ly</div>
              </div>
              <div className="items Paragraph-2LeftSlate">
                {this.extraLicences}x{formatter.format(licensePrice)}
              </div>
              <div className="totals Button-Left-Blue">
                {formatter.format(this.extraLicences * licensePrice)}
              </div>
            </SummaryItem>
          ) : null}
          <SummaryTotal>
            <div className="inline-header Heading-6-Left-Basalt">Total</div>
            <div className="totals Heading-6-Left-Basalt">
              {formatter.format(planPrice + this.extraLicences * licensePrice)}
            </div>
          </SummaryTotal>
          <SummaryText>
            <p>
              You’ll be charged{' '}
              <strong>{formatter.format(planPrice + this.extraLicences * licensePrice)}</strong> on
              a {this.plan.interval}ly basis{' '}
              {this.plan.interval === 'month'
                ? 'with a minimum one month cancellation.'
                : 'for a minimum 1 year term subscription.'}
            </p>
            {hasFreeTrial ? (
              <p>
                <strong>
                  You’re first payment will be taken once your trial period has finished.
                </strong>
              </p>
            ) : null}
          </SummaryText>
        </Summary>
      </ColumnContainer>
    );
  }
}

export default withRouter(OrderSummary);
