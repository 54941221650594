import React from 'react';

export default ({
  label,
  labelClassName = '',
  desc,
  options,
  inputRef,
  isReq,
  isError,
  errorMessage,
  isDefaultBlank,
  optionPlaceholder,
  ...rest
}) => {
  return (
    <div className="form-item">
      {label ? (
        <label className={labelClassName}>
          {label}
          &nbsp;
          {isError ? <span className="is-error">{errorMessage}</span> : null}
        </label>
      ) : null}

      <select ref={inputRef} {...rest}>
        {isDefaultBlank && <option value=""></option>}
        {optionPlaceholder && (
          <option value={optionPlaceholder.value || ''} hidden>
            {optionPlaceholder.label || 'Select...'}
          </option>
        )}
        {options.map((option, index) => (
          <option
            className={option.className}
            hidden={option.hidden}
            disabled={option.disabled}
            key={`option-${index}-${option.value}`}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>

      {desc ? <div className="is-desc">{desc}</div> : null}
    </div>
  );
};
