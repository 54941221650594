import styled from '@emotion/styled';

export const ErrorMessage = styled.div`
  display: ${(props) => (props.isError ? '' : 'none')};
  background: rgba(238, 36, 85, 0.07);
  border: 1px solid rgba(238, 36, 85, 0.3);
  color: #ee2455;
  font-size: 0.75em;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export default ErrorMessage;
