import React, { Component } from 'react';
import { v4 } from 'uuid';
import { ShowPassword } from './Components';
import CheckboxStyled from '../../forms/CheckboxStyled';

export default class Input extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showPassword: false,
  };

  get type() {
    if (this.props.type === 'password' && this.state.showPassword) return 'text';

    return this.props.type;
  }

  render() {
    const {
      label,
      labelClassName = '',
      wrapClassName = '',
      renderHeader,
      desc,
      inputRef,
      isReq,
      isError,
      errorMessage,
      ...rest
    } = this.props;

    const id = rest.id || v4();

    return (
      <div className={`form-item ${wrapClassName}`}>
        {label ? (
          <label className={labelClassName} htmlFor={id}>
            {label}
            &nbsp;
            {isError ? <span className="is-error">{errorMessage}</span> : null}
          </label>
        ) : null}
        {renderHeader}
        <input ref={inputRef} {...rest} id={id} type={this.type} />

        {rest.type === 'password' && (
          <ShowPassword className="show-password-wrap">
            <CheckboxStyled
              type="checkbox"
              id={`${id}-show-password`}
              onChange={(e) => this.setState({ showPassword: !this.state.showPassword })}
              label="Show password"
            />
          </ShowPassword>
        )}
        {desc ? <div className="is-desc">...</div> : null}
      </div>
    );
  }
}
