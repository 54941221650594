import React from 'react';
import styled from '@emotion/styled';
import PaymentSuccessImage from '../../../elements/SuccessIcon';
import Button from '../../../ui/Button';

const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: #fff;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;

  .header {
    font-size: 2rem;
    font-weight: 600;
    flex: 0 0 100%;
    margin-bottom: 40px;
  }
`;

const Modal = styled.div`
  height: 480px;
  width: 400px;
  border-radius: 28px;
  box-shadow: 0 11px 23px 0 rgba(0, 0, 0, 0.18);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap;
  padding: 32px;

  .image-container {
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
  }

  .body {
    .header {
      font-weight: 600;
      font-size: 1.5rem;
      padding: 0;
      text-align: center;
      margin: 0;
    }

    .text {
      text-align: center;
    }
  }

  .button {
    flex: 0 0 100%;
  }
`;

function PaymentSuccess({ active, handleAction }) {
  if (!active) return null;

  const handleClick = () => {
    window.location = '/app';
  };

  return (
    <Container>
      <h1 className="header">Payment Complete</h1>
      <Modal>
        <div className="image-container">
          <PaymentSuccessImage />
        </div>
        <div className="body">
          <div className="header">Done!</div>
          <div className="text">Payment received</div>
        </div>
        <Button className="button" label="Let's get started" onClick={handleClick} />
      </Modal>
    </Container>
  );
}

export default PaymentSuccess;
