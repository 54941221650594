import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { get } from '../../../../helpers/api';
import ErrorMessage from '../../../ui/ErrorMessage';

import { Container, ColumnTwo, ColumnOne, FormContainer } from '../../Homepage/components';
import OrderSummary from './OrderSummary';
import PaymentForm from './PaymentForm';
import { redirectUserToCorrectPage } from '../../Homepage/utils';
import Loading from '../../../ui/Loading';
/*  state default
pkgs[
  pkg {
    header: '',
    title: '',
    info:[string, string, sring]/* dangerouslysetinnerhtml ,
    plans [{title, details, description, units, stripe account, amount (pennys), interval, nickname, id, currency, baseQuantity, quantityAmount, flatAmount}]
  }
]

Plan to pass state for adjust plan as props to plan component
adjust plan will use local state
*/

const paymentInitData = {
  id: '',
  type: '',
  created: '',
  cardBrand: '',
  cardCountry: '',
  cardExpiry: '',
  cardLast4: '',
  name: '',
};

export class Payment extends Component {
  state = {
    pkg: null,
    errors: {
      packages: [],
    },
    licenses: null,
    paymentData: paymentInitData,
    hasPaymentData: false,
    loading: null,
    paymentMethods: null,
  };
  queryString = queryString.parse(this.props.location.search);

  async componentDidMount() {
    if (!this.props.countryCode) return;
    await this.getData();
  }

  getData = async () => {
    this.setState({ loading: true });
    const { packageId, packageToken, planId, licenses } = this.queryString;
    let response = null;
    let errors = { ...this.state.errors };

    if (!packageId || !planId || !licenses) {
      errors.packages.push('Invalid url, please check it and try again');
      this.setState({ errors });
      return;
    }

    if (packageToken) response = await get(`public/packages/partner/${packageToken}/${packageId}`);
    else {
      response = await get(
        `public/packages/${this.queryString.packageId}?countryCode=${this.props.countryCode}`
      );
    }

    if (!response.ok) {
      if (response.status === 404) {
        errors.packages.push('Package not found, please check your URL');
      } else
        errors.packages.push(
          'There was an issue retrieving the package information. Please refresh and try again.'
        );
      this.setState({ errors });
      return;
    }

    this.setState({ pkg: response.body[0] });

    response = await get('payment/retrieve-customer-subscription');
    if (response.ok) {
      const paymentData = response.body.paymentMethods[0]
        ? this.getPaymentMethodsFromApi(response.body.paymentMethods[0])
        : paymentInitData;
      if (paymentData.name && paymentData.cardLast4 && paymentData.cardExpiry) {
        this.setState({ paymentData, hasPaymentData: true });
      }
      this.setState({ paymentMethods: response.body.paymentMethods[0] });
    }
    this.setState({ loading: false });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.countryCode !== this.props.countryCode) {
      await this.getData();
    }
  };

  get stripeKey() {
    if (!this.state.pkg) return null;
    if (!this.state.pkg.stripeProduct) return null;
    if (this.state.pkg.stripeAccount === 'uk') return this.props.stripeKeyUK;
    return this.props.stripeKey;
  }

  get stripe() {
    if (!this.stripeKey) return null;
    return loadStripe(this.stripeKey);
  }

  get selectedPlan() {
    if (!this.state.pkg) return null;
    if (!this.state.pkg.stripeProduct) return null;
    if (!this.queryString.planId) return null;

    const plan = this.state.pkg.stripeProduct.stripePrices.find(
      (price) => price.id === this.queryString.planId
    );
    if (!plan) return null;
    return plan;
  }

  getPaymentMethodsFromApi = (paymentMethods) => {
    return {
      id: paymentMethods.id,
      type: paymentMethods.type,
      created: paymentMethods.created
        ? new Date(+paymentMethods.created * 1000).toDateString()
        : '',
      cardBrand: paymentMethods.card.brand,
      cardCountry: paymentMethods.card.country,
      cardExpiry: `${paymentMethods.card.exp_month} / ${paymentMethods.card.exp_year}`,
      cardLast4: paymentMethods.card.last4,
      name: paymentMethods.billing_details.name,
    };
  };

  get cardBrand() {
    if (!this.state.paymentData) return '';
    return this.state.paymentData.cardBrand;
  }
  get cardEnding() {
    if (!this.state.paymentData) return '';
    return this.state.paymentData.cardLast4;
  }

  get cardExpiry() {
    if (!this.state.paymentData) return '';
    return this.state.paymentData.cardExpiry;
  }

  get cardName() {
    if (!this.state.paymentData) return '';
    return this.state.paymentData.name;
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <Container>
        <ColumnOne center light>
          {this.state.errors.packages.length ? (
            <ErrorMessage isError={this.state.errors.packages.length}>
              {this.state.errors.packages[0]}
            </ErrorMessage>
          ) : (
            <OrderSummary
              pkg={this.state.pkg}
              user={this.props.user}
              isUpgrade={this.props.isUpgrade}
            />
          )}
        </ColumnOne>
        <ColumnTwo>
          {this.state.pkg && this.state.pkg.stripeAccount === 'demo' ? (
            <PaymentForm
              pkg={this.state.pkg}
              stripeKey={this.stripeKey}
              packageToken={this.queryString.packageToken}
              user={this.props.user}
              isFreeTrial={this.queryString.freeTrial === '1'}
              selectedPlan={this.selectedPlan}
              licenses={this.state.licenses}
              isUpgrade={this.props.isUpgrade}
              hasPaymentData={this.state.hasPaymentData}
              cardBrand={this.cardBrand}
              cardExpiry={this.cardExpiry}
              cardName={this.cardName}
              paymentMethods={this.state.paymentMethods}
              cardEnding={this.cardEnding}
            />
          ) : (
            <Elements stripe={this.stripe}>
              <PaymentForm
                pkg={this.state.pkg}
                stripeKey={this.stripeKey}
                packageToken={this.queryString.packageToken}
                user={this.props.user}
                isFreeTrial={this.queryString.freeTrial === '1'}
                selectedPlan={this.selectedPlan}
                licenses={this.queryString.licenses}
                isUpgrade={this.props.isUpgrade}
                hasPaymentData={this.state.hasPaymentData}
                cardBrand={this.cardBrand}
                cardExpiry={this.cardExpiry}
                cardName={this.cardName}
                paymentMethods={this.state.paymentMethods}
                cardEnding={this.cardEnding}
              />
            </Elements>
          )}
        </ColumnTwo>
      </Container>
    );
  }
}

export default withRouter(Payment);
