import React from 'react';
import close from '../../assets/svg/close.svg';
import remove from '../../assets/svg/remove.svg';
import plus from '../../assets/svg/icon-plus-16-px.svg';
import kebab from '../../assets/svg/kebab.svg';
import info from '../../assets/svg/info.svg';
import print from '../../assets/svg/print.svg';
import pencil from '../../assets/svg/pencil.svg';
import pencil16 from '../../assets/svg/icon-edit-16-px.svg';
import history24 from '../../assets/svg/icon-history-24-px.svg';

const icons = {
  close,
  remove,
  plus,
  kebab,
  info,
  print,
  pencil,
  pencil16,
  history24,
};

export default ({ name, className, ...rest }) => {
  return (
    (
      <div {...rest} className={`${name}-${className} ${className}`}>
        {icons[name]()}
      </div>
    ) || <div />
  );
};
